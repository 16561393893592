body, .form-control {
	font-size: @font-size-small;
	 @media (min-width: @screen-sm-min) {
	 	font-size: @font-size-base;
	 }
}
label.btn {
	font-size: 12px;
	 @media (min-width: @screen-sm-min) {
	 	font-size: @font-size-base;
	 }
}
h1 {
	font-size: @font-size-h2;
	 @media (min-width: @screen-sm-min) {
	 	font-size: @font-size-h1;
	 }
}
h2 {
	font-size: @font-size-h3;
	 @media (min-width: @screen-sm-min) {
	 	font-size: @font-size-h2;
	 }
}

.header {
	background: @gray-lighter;
	padding: @grid-gutter-width 0;
	margin-bottom: @grid-gutter-width / 2;
	.text-center;
	@media (min-width: @screen-sm-min) {
		margin-bottom: @grid-gutter-width;
	}
	p {
		margin: 0;
	}
	img {
		width: 160px;
	}
}
.main {
	padding-bottom: @grid-gutter-width * 2;
}
textarea.form-control {
	height: 120px;
	@media (min-width: @screen-sm-min) {
		height: 170px;
	}
}
.btn {
	-webkit-font-smoothing: antialiased;
}
.container {
	max-width: 640px;
}
.btn-group {
	margin-bottom: @grid-gutter-width;
	.btn {
		color: @gray;
		img {
			display: block;
			margin: 0 auto 10px;
			width: 50px;	
			@media (min-width: @screen-sm-min) {
				width: 65px;		
			}
		}
		padding: 15px 0;
		@media (min-width: @screen-sm-min) {
			padding: 30px 0;
		}
		&.chosen {
			color: @gray-dark;
			border-color: @gray;
		}
	}
}
